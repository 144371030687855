const quotes = [
    {
      quote: 'Remastered Dimension Intrusion & Train-Tracs'
    },
    {
      quote: 'Unreleased Computer Space album'
    },
    {
      quote: 'Unreleased bonus tracks'
    },
    {
      quote: 'Artist grade prints by Matthew Hawtin'
    },
    {
      quote: 'Historical booklet with new text & images'
    },
    {
      quote: 'Custom box designed by Matthew Hawtin'
    },
    {
      quote: 'Digital download codes'
    },
    {
      quote: 'Limited Edition'
    }
  ]

  








export default quotes;