import React, { Component } from 'react';
import Fuse from './components/Fuse';

import './App.scss';


class App extends Component {
  render() {


    return (
      <div className="App">

        <Fuse/>
  
      </div>
    );
  }
}

export default App;
